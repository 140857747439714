@mixin cardImageZoom {
  transform: scale(1.07);
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-row {
  display: flex;
  flex-direction: row;
}

// mixin to only apply hover styles when hover is supported to avoid sticky hover states
@mixin hover {
  @media (hover: hover) {
    &:hover {
      @content;
    }
  }
}

@mixin focusOrHover {
  &:focus-visible {
    @content;
  }

  @include hover {
    @content;
  }
}

@mixin lineClamp($lineCount) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: $lineCount;
  -webkit-line-clamp: $lineCount;
  -webkit-box-orient: vertical;
}

@mixin minWidth($breakpoint) {
  @media all and (min-width: $breakpoint) {
    @content;
  }
}

@mixin maxWidth($breakpoint) {
  @media all and (max-width: $breakpoint) {
    @content;
  }
}

@mixin placeholderColor($color) {
  // We must use separate selectors for each vendor prefix because some browsers don't support all of them and the css breaks
  &::placeholder {
    color: $color;
  }

  &::-webkit-input-placeholder {
    color: $color;
  }

  &::-ms-input-placeholder {
    color: $color;
  }
}

@mixin removeDefaultButtonStyles {
  padding: 0;
  border: none;
  background-color: transparent;
}

@mixin removeScrollBar {
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0 !important;
    display: none !important;
  }
}

@mixin userSelectNone {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
