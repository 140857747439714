@import "styles/core.scss";
.container {
  @include flex-column;
}

.tabBar {
  margin: 0 var(--space-300) var(--space-300) var(--space-300);
}

.scrollable {
  height: 100%;
  overflow-y: scroll;
  padding-bottom: var(--space-200);
}

.emptyState,
.loadingState {
  @include flex-column;
  justify-content: center;
  align-items: center;
  padding: var(--space-150) var(--space-300);

  @include page-width-md-up {
    min-height: 200px;
  }
}

.emptyState {
  .primary,
  .secondary {
    word-wrap: break-word;
    text-align: center;
  }

  .primary {
    @include text-200-bold;
    margin-bottom: var(--space-50);
  }

  .secondary {
    @include text-100;
  }
}
