@import './mixins.scss';

$font-family-percent: Percent;
$primary-font: var(--font-family-primary), Arial, sans-serif;
$secondary-font: Percent, var(--font-family-secondary), Arial, sans-serif;

@mixin heading-700 {
  color: var(--color-text-primary);
  font-family: $secondary-font;
  font-size: var(--font-size-700);
  font-weight: var(--font-weight-normal);
  letter-spacing: var(--font-letterspacing-700);
  line-height: var(--font-lineheight-700);

  @include page-width-md-up {
    font-size: var(--font-size-700-responsive);
    letter-spacing: var(--font-letterspacing-700-responsive);
    line-height: var(--font-lineheight-700-responsive);
  }
}

@mixin heading-600 {
  color: var(--color-text-primary);
  font-family: $secondary-font;
  font-size: var(--font-size-600);
  font-weight: var(--font-weight-normal);
  line-height: var(--font-lineheight-600);

  @include page-width-md-up {
    font-size: var(--font-size-600-responsive);
    letter-spacing: var(--font-letterspacing-600-responsive);
    line-height: var(--font-lineheight-600-responsive);
  }
}

@mixin heading-500 {
  color: var(--color-text-primary);
  font-family: $secondary-font;
  font-size: var(--font-size-500);
  font-weight: var(--font-weight-normal);
  line-height: var(--font-lineheight-500);

  @include page-width-md-up {
    font-size: var(--font-size-500-responsive);
    letter-spacing: var(--font-letterspacing-500-responsive);
    line-height: var(--font-lineheight-500-responsive);
  }
}

@mixin heading-400 {
  color: var(--color-text-primary);
  font-family: $secondary-font;
  font-size: var(--font-size-400);
  font-weight: var(--font-weight-normal);
  line-height: var(--font-lineheight-400);

  @include page-width-md-up {
    font-size: var(--font-size-400-responsive);
    letter-spacing: var(--font-letterspacing-400-responsive);
    line-height: var(--font-lineheight-400-responsive);
  }
}

@mixin heading-300 {
  font-family: $primary-font;
  font-size: var(--font-size-300);
  font-weight: var(--font-weight-bold);
  line-height: var(--font-lineheight-default);
  color: var(--color-text-primary);
}

@mixin heading-200 {
  font-family: $primary-font;
  font-size: var(--font-size-200);
  font-weight: var(--font-weight-bold);
  line-height: var(--font-lineheight-default);
  color: var(--color-text-primary);
}

@mixin heading-100 {
  font-family: $primary-font;
  font-size: var(--font-size-100);
  font-weight: var(--font-weight-bold);
  line-height: var(--font-lineheight-default);
  color: var(--color-text-primary);
}

@mixin heading-100-eyebrow {
  font-family: $primary-font;
  font-size: var(--font-size-100);
  font-weight: var(--font-weight-bold);
  line-height: var(--font-lineheight-default);
  color: var(--color-text-primary);
  text-transform: uppercase;
  letter-spacing: 2px;
}

@mixin text-300 {
  font-family: $primary-font;
  font-size: var(--font-size-300);
  font-weight: var(--font-weight-normal);
  line-height: var(--font-lineheight-default);
  color: var(--color-text-primary);
}

@mixin text-200 {
  font-family: $primary-font;
  font-size: var(--font-size-200);
  font-weight: var(--font-weight-normal);
  line-height: var(--font-lineheight-default);
  color: var(--color-text-primary);
}

@mixin text-100 {
  font-family: $primary-font;
  font-size: var(--font-size-100);
  font-weight: var(--font-weight-normal);
  line-height: var(--font-lineheight-default);
  color: var(--color-text-primary);
}

@mixin text-50 {
  font-family: $primary-font;
  font-size: var(--font-size-50);
  font-weight: var(--font-weight-normal);
  line-height: var(--font-lineheight-default);
  color: var(--color-text-primary);
}

@mixin text-300-bold {
  font-family: $primary-font;
  font-size: var(--font-size-300);
  font-weight: var(--font-weight-bold);
  line-height: var(--font-lineheight-default);
  color: var(--color-text-primary);
}

@mixin text-200-bold {
  font-family: $primary-font;
  font-size: var(--font-size-200);
  font-weight: var(--font-weight-bold);
  line-height: var(--font-lineheight-default);
  color: var(--color-text-primary);
}

@mixin text-100-bold {
  font-family: $primary-font;
  font-size: var(--font-size-100);
  font-weight: var(--font-weight-bold);
  line-height: var(--font-lineheight-default);
  color: var(--color-text-primary);
}

@mixin text-50-bold {
  font-family: $primary-font;
  font-size: var(--font-size-50);
  font-weight: var(--font-weight-bold);
  line-height: var(--font-lineheight-default);
  color: var(--color-text-primary);
}

@mixin paragraph-300 {
  font-family: $primary-font;
  font-size: var(--font-size-300);
  font-weight: var(--font-weight-normal);
  line-height: var(--font-lineheight-loose);
  color: var(--color-text-primary);
}

@mixin paragraph-200 {
  font-family: $primary-font;
  font-size: var(--font-size-200);
  font-weight: var(--font-weight-normal);
  line-height: var(--font-lineheight-loose);
  color: var(--color-text-primary);
}

@mixin paragraph-100 {
  font-family: $primary-font;
  font-size: var(--font-size-100);
  font-weight: var(--font-weight-normal);
  line-height: var(--font-lineheight-loose);
  color: var(--color-text-primary);
}
